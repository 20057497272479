import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"

import { GlobalStyle } from "src/styles/globalStyle"
import { theme } from "src/styles/theme"

import { SEO } from "../SEO"

export const LayoutDefault: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `}
    render={() => (
      <>
        <ThemeProvider theme={theme}>
          <GlobalStyle />

          <Helmet>
            {/* FONTS */}
            <link
              rel="stylesheet"
              href="https://use.typekit.net/pzn4xsp.css"
            ></link>
          </Helmet>

          <SEO />
          {children}
        </ThemeProvider>
      </>
    )}
  />
)
