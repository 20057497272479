export const theme = {
  color: {
    primary: "#BF985F",
    secondary: "#3D5D68",
    neutral: "#B0C0C8",
    light: "#EEF3F7",
    dark: "#384452",
    gray: "#B1C1C9",
  },
  fontFamily: {
    sans: "futura-pt, sans-serif", // 400, 500
    serif: "freight-big-pro, serif", // 300, 400, 500
  },
  breakpoints: {
    mobile: "576px",
    tablet: "768px",
    desktop: "1200px",
  },
  grid: {
    gap: "30px",
  },
}
