import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { globalSettings } from "src/components/globalSettings"
import { Container } from "src/components/ui/Container"
import linkedinSvg from "src/images/icons/linkedin.svg"
import { MEDIA_QUERIES } from "src/styles/mediaQueries"
import { validateInternalLink } from "src/utils/validateInternalLink"

interface Props {
  isHiddenOnDesktop?: boolean
}

export const Footer: React.FC<Props> = ({ isHiddenOnDesktop }) => {
  const {
    telephone,
    linkedInUrl,
    privacyPolicyPage,
    creditsPageUrl,
  } = globalSettings()

  return (
    <OuterContainer isHiddenOnDesktop={isHiddenOnDesktop}>
      <Container>
        <Wrapper>
          <FooterItems>
            <li>&copy; {new Date().getFullYear()}</li>
            <li>All rights reserved</li>
            {creditsPageUrl && (
              <li>
                <a
                  href={creditsPageUrl}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Site Credit
                </a>
              </li>
            )}
            <li>
              <Link to={validateInternalLink(privacyPolicyPage?.slug)}>
                Privacy Policy
              </Link>
            </li>
            <li>{telephone}</li>
          </FooterItems>
          <div>
            <a
              href={linkedInUrl}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <img src={linkedinSvg} alt="LinkedIn Icon" />
            </a>
          </div>
        </Wrapper>
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.div<Props>`
  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    display: ${({ isHiddenOnDesktop }) =>
      isHiddenOnDesktop ? "none" : "block"};
  }
`

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 55px 0;
  color: #484a47;
  font-family: ${({ theme }) => theme.fontFamily.sans};
`

const FooterItems = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${MEDIA_QUERIES.desktop} {
    li + li {
      margin-left: 50px;
    }
  }

  @media ${MEDIA_QUERIES.mobileAndTablet} {
    flex-direction: column;
    li + li {
      margin-top: 0.5rem;
    }
  }

  a {
    color: #484a47;
  }
`
