import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"

import { globalSettings } from "src/components/globalSettings"
import { MEDIA_QUERIES } from "src/styles/mediaQueries"
import { validateInternalLink } from "src/utils/validateInternalLink"

interface Props {
  isOpen: boolean
}

export const MainMenu: React.FC<Props> = ({ isOpen }) => {
  useEffect(() => {
    if (window) {
      const overflowValue = isOpen ? "hidden" : ""
      document.getElementsByTagName("body")[0].style.overflow = overflowValue
    }
  }, [isOpen])

  const { menu } = globalSettings()

  return (
    <Wrapper isOpen={isOpen}>
      <Menu isOpen={isOpen}>
        {menu.map((item) => (
          <MenuItem key={item.id}>
            <Link to={validateInternalLink(item.slug)} activeClassName="active">
              {item.labelForMenu || item.pageTitle}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  )
}

interface WrapperProps {
  isOpen: boolean
}
const Wrapper = styled.div<WrapperProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.color.secondary};
  padding: 0 100px;

  transform: translate(100%, 0);
  transition: transform 0.5s ease-in-out;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: translate(0, 0);

          ul > li {
            transform: translate(0, 0);
          }
        `
      : null}
`

const MenuItem = styled.li`
  font-size: 40px;
  font-family: ${({ theme }) => theme.fontFamily.serif};
  font-weight: 100;
  margin-bottom: 1rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    font-size: 76px;
  }

  &:hover {
    opacity: 1 !important;
  }
  a {
    display: block;
    color: #fff;
    opacity: 1 !important;
    &.active {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`

interface MenuProps {
  isOpen: boolean
}
const Menu = styled.ul<MenuProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:hover {
    ${MenuItem} {
      opacity: 0.5;
    }
  }

  overflow: hidden;

  li > a {
    /* transition: transform 0.3s ease-in-out 1s; */
    transform: translate(100%, 0);
  }

  li:nth-of-type(1) {
    a {
      transition: transform 0.3s ease-in-out 0.6s;
    }
  }
  li:nth-of-type(2) {
    a {
      transition: transform 0.3s ease-in-out 0.8s;
    }
  }
  li:nth-of-type(3) {
    a {
      transition: transform 0.3s ease-in-out 1s;
    }
  }
  li:nth-of-type(4) {
    a {
      transition: transform 0.3s ease-in-out 1.2s;
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          li > a {
            transform: translate(0, 0);
          }
        `
      : css``}
`
