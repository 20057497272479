import { createGlobalStyle } from "styled-components"

import { theme } from "src/styles/theme"

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: ${theme.fontFamily.serif};
    background-color: #fff;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.color.dark};
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba($black, 0);
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  a {
    color: ${theme.color.primary};
    text-decoration: none;

    &:hover {
      opacity: 0.8
    }
  }
`
