export const validateInternalLink = (slug = "#") => {
  if (slug === "home") {
    return "/"
  }

  if (slug === "/") {
    return slug
  }

  const slugWithSlashes = `/${slug}/`.replace(/\/\//g, "/")
  return slugWithSlashes
}
