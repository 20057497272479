import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import { Facebook } from "./Facebook"

interface Props {
  title?: string
  description?: string
  pathname?: string
  datePublished?: string
  dateModified?: string
  canonical?: string
  seoAttributes?: string[]
}

export const SEO: React.FC<Props> = ({
  title,
  description,
  canonical,
  pathname,
  seoAttributes,
}) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      facebook,
      siteLanguage,
      ogLanguage,
    },
  } = site

  const image = "/images/gb-logo-hq.png"
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "Organization",
    url: siteUrl,
    logo: `${siteUrl}${image}`,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="canonical" content={canonical} />
        {seoAttributes && seoAttributes.length ? (
          <meta name="robots" content={seoAttributes.join(", ")} />
        ) : null}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={"website"}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
    </>
  )
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        siteLanguage
        ogLanguage
        facebook
      }
    }
  }
`
