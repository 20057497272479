import { Link } from "gatsby"
import React, { useState } from "react"
import styled, { css } from "styled-components"

import { globalSettings } from "src/components/globalSettings"
import { Container } from "src/components/ui/Container"
import { MEDIA_QUERIES } from "src/styles/mediaQueries"

import { MainMenu } from "./MainMenu"

interface Props {
  floating: boolean
}

export const Header: React.FC<Props> = ({ floating }) => {
  const { headerLogo } = globalSettings()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <Wrapper floating={floating}>
      <Container>
        <Nav>
          <AlignerMobile />
          <LogoWrapper to={"/"}>
            <Logo src={headerLogo.file?.url} alt="GB Executive logo" />
          </LogoWrapper>
          <MenuWrapper>
            <MenuButton
              isMenuOpen={isMenuOpen}
              onClick={() => {
                setIsMenuOpen((state) => !state)
              }}
            >
              <span />
              <span />
            </MenuButton>
          </MenuWrapper>
          <MainMenu isOpen={isMenuOpen} />
        </Nav>
      </Container>
    </Wrapper>
  )
}

interface WrapperProps {
  floating: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({ floating }) =>
    floating
      ? css`
          @media ${MEDIA_QUERIES.tabletAndDesktop} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 200;
          }
        `
      : null}
`

const AlignerMobile = styled.div`
  display: block;
  width: 44px;
  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    display: none;
  }
`

const Nav = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 40px 0 40px;
  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    padding: 44px 0 40px;
  }
`

const LogoWrapper = styled(Link)`
  display: inline-block;
`

const Logo = styled.img`
  max-width: 84px;
`

const MenuWrapper = styled.div`
  position: relative;
  z-index: 200;
  display: flex;
  align-items: center;
`

interface MenuButtonProps {
  isMenuOpen: boolean
}

const MenuButton = styled.button<MenuButtonProps>`
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  outline: none;

  width: 44px;
  height: 40px;
  @media ${MEDIA_QUERIES.tabletAndDesktop} {
    width: 54px;
    height: 50px;
  }

  span {
    display: block;
    border-top: 1px solid ${({ theme }) => theme.color.primary};
    transition: width 0.4s ease-in-out;

    &:nth-of-type(1) {
      margin-bottom: 14px;
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 30px;

      opacity: 1;
      transition: opacity 0.4s ease-in-out;
      ${({ isMenuOpen }) =>
        isMenuOpen
          ? css`
              opacity: 0;
            `
          : null}
    }
  }

  &:hover {
    span {
      &:nth-of-type(1) {
        width: 30px;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }
`
