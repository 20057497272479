import React from "react"
import styled, { css } from "styled-components"

export const Container: React.FC = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

export const ContainerSize = css`
  width: 1346px;
  max-width: 90vw;
`

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  ${ContainerSize}
`
