import { graphql, useStaticQuery } from "gatsby"

import { SettingsQuery } from "src/generated/graphql"

export const globalSettings = () => {
  const settings = useStaticQuery<SettingsQuery>(globalSettingQuery)
  return {
    ...settings.contentfulSettings,
  }
}

const globalSettingQuery = graphql`
  query Settings {
    contentfulSettings(contentful_id: { eq: "3gt8Y6jL9tmvZDWqkf8VPF" }) {
      headerLogo {
        title
        file {
          url
        }
      }
      linkedInUrl
      telephone
      privacyPolicyPage {
        slug
      }
      creditsPageUrl
      menu {
        id
        slug
        labelForMenu
        pageTitle
      }
    }
  }
`
